import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

type TabCategoryProps = {
  active?: boolean
}

export const PageTitle = styled.div`
  display: flex;
`
export const VideoContainer = styled.div`
  iframe {
    border-radius: 16px;
    max-width: 100%;
  }
`
export const VideoCardSmall = styled.img`
  border-radius: 16px;
  width: 100%;
  height: 131px;
  border: 1px solid #dedfe4;
  cursor: pointer;
  min-width: 231px;

  & + img {
    margin-top: 0;
    margin-left: 11px;
  }
  @media (min-width: ${breakpoints.lg}) {
    & + img {
      margin-left: 0;
      margin-top: 11px;
    }
  }
`
export const ListCategory = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
`
export const TabCategory = styled.li`
  button {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid #dedfe4;
    color: ${(props: TabCategoryProps) => (props.active ? '#ffffff' : '#161616')};
    background: ${(props: TabCategoryProps) => (props.active ? '#161616' : '#ffffff')};
    font-family: Inter;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
  }

  & + li {
    margin-left: 8px;
  }
`
export const Scroll = styled(PerfectScrollbar)`
  padding: 16px 16px;
`
export const VideosContainer = styled.div`
  max-height: 405px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const Live = styled.div`
  width: 42px;
  height: 21px;
  background: #c5232a;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
  }
`
