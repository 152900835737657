/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-datepicker'
import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'
// Data
import calendarEventsJson from 'src/assets/data/calendar-events.json'

// Styles
import {
  EventsContainer,
  EventContainer,
  Scroll,
  CalendarContainer,
  CalendarHeader,
  DayComponent,
} from './style'

type eventsProps = {
  label: string
  value: string
  initial_time: string
  final_time: string
  live_day: boolean
}

type DayProps = {
  date: string
  live: boolean
  news: boolean
  events: eventsProps[]
}

const CalendarEvents = () => {
  const [day, setDay] = useState(new Date())
  const [events, setEvents] = useState<eventsProps[]>([])
  const [noEvents, setNoEvents] = useState(false)
  const [sendDatalayerEvent] = useDataLayer()

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  useEffect(() => {
    try {
      const dayEvents = calendarEventsJson.filter(
        (item: DayProps) => item.date === day.toLocaleDateString(),
      )
      setEvents([])
      setEvents(Array.from(dayEvents)[0].events)
      setNoEvents(false)
    } catch (error) {
      setNoEvents(true)
    }
  }, [day])

  const renderDayContents = (currentDay: number, date: Date) => {
    const dayEvents = calendarEventsJson.filter(
      (item: DayProps) => item.date === format(date, 'dd/MM/yyyy', { locale: pt }),
    )

    return (
      <DayComponent
        live={dayEvents[0]?.live}
        news={dayEvents[0]?.events.length > 0}
        title={`${currentDay}`}
      >
        {date.getDate()}
      </DayComponent>
    )
  }

  const date_destr = format(day, "dd 'de' MMMM", { locale: pt }).split(' ')

  const formString = () => (
    <h3 className="fs-16 lh-20 fw-600">
      {date_destr[0]} de <span className="capialtize"> {date_destr[2]}</span>
    </h3>
  )

  return (
    <section className="pt-5 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-8">
            <EventsContainer>
              <div>
                <h3 className="fs-16 lh-20 fw-600 title">{formString()}</h3>
              </div>
              {noEvents ? (
                <div className="mensage">
                  <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--490">
                    Não há eventos
                  </p>
                </div>
              ) : (
                <Scroll
                  ps-scroll-right
                  options={{
                    wheelSpeed: 2,
                  }}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: '',
                      element_action: 'click scroll',
                      element_name: '',
                      redirect_url: '',
                      section_name: '',
                    })
                  }}
                >
                  {events.map((item: eventsProps) => (
                    <EventContainer key={item.label + item.value} isLive={item.live_day}>
                      <div className="hours">
                        <span className="hours-inital">{item.initial_time}</span>
                        <span className="hours-final">{item.final_time}</span>
                      </div>
                      <div className="event">
                        <div>
                          <span className="event-category">{item.label}</span>
                          <p className="event-title">{item.value}</p>
                        </div>
                      </div>
                    </EventContainer>
                  ))}
                </Scroll>
              )}
            </EventsContainer>
          </div>
          <div className="col-md-5 col-lg-4 d-none d-md-flex justify-content-end">
            <CalendarContainer>
              <DatePicker
                selected={day}
                onChange={(date: Date) => setDay(date)}
                className="calendar"
                locale="pt-BR"
                inline
                renderDayContents={renderDayContents}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <CalendarHeader className="d-flex align-items-center justify-content-between">
                    <button
                      type="button"
                      onClick={() => {
                        decreaseMonth()
                        sendDatalayerEvent({
                          section: '',
                          element_action: 'click button',
                          element_name: '',
                          section_name: '',
                        })
                      }}
                      disabled={prevMonthButtonDisabled}
                    >
                      <OrangeDsIcon size="SM" color="#FF7A00" icon="chevron-left" />
                    </button>
                    <span className="month">{months[date.getMonth()]}</span>
                    <button
                      type="button"
                      onClick={() => {
                        increaseMonth()
                        sendDatalayerEvent({
                          section: '',
                          element_action: 'click button',
                          element_name: '',
                          section_name: '',
                        })
                      }}
                      disabled={nextMonthButtonDisabled}
                    >
                      <OrangeDsIcon size="SM" color="#FF7A00" icon="chevron-right" />
                    </button>
                  </CalendarHeader>
                )}
              />
            </CalendarContainer>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CalendarEvents
