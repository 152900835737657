import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'

import { parseISO, isBefore } from 'date-fns'
import ScrollContainer from 'react-indiana-drag-scroll'
import homeMidia from 'src/assets/data/home-midia.json'
import useWidth from 'src/hooks/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import YoutubeVideo from '../YoutubeVideo'
import {
  VideoCardSmall,
  VideoContainer,
  ListCategory,
  TabCategory,
  VideosContainer,
  Live,
} from './style'
import OrangeIcon from '../Icon/OrangeDsIcon'

type HomeMidia = {
  category: VideoProps[]
  video: CategoryProps[]
}

type ThumbnailProps = {
  url: string
  alt: string
}

type VideoProps = {
  uuid: string
  category: string
  link: string
  image: string
  isLive?: boolean
  description: string
  title: string
  thumbnail: ThumbnailProps
  date: string
}

type CategoryProps = {
  id: string
  name?: string | undefined
  active?: boolean | undefined
}

const WIDTH_LG = 1024
const WIDTH_MD = 768

// Odernate
function ordenate(list: VideoProps[]) {
  const videosOrdenate = list.sort((a, b) => {
    const aDate = parseISO(a.date)
    const bDate = parseISO(b.date)
    return isBefore(aDate, bDate) ? 1 : -1
  })

  return videosOrdenate
}

const HomeMidia = () => {
  const [videoActive, setvideoActive] = useState<VideoProps>(ordenate(homeMidia.videos)[0])
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const scrollContainerRef = useRef<HTMLElement>(null)
  const [videoCard, setvideoCard] = useState<VideoProps[]>(ordenate(homeMidia.videos))
  const [isTablet, setIsTablet] = useState(true)
  const [isMobile, setIsMobile] = useState(true)
  const windowWidth = useWidth(300)
  const [sendDatalayerEvent] = useDataLayer()

  useLayoutEffect(() => {
    setIsTablet(windowWidth < WIDTH_LG)
    setIsMobile(windowWidth < WIDTH_MD)
  }, [windowWidth])

  useEffect(() => {
    const newList = homeMidia.videos.filter((item) => item.uuid !== videoActive.uuid)
    setvideoCard(ordenate(newList))
  }, [videoActive])

  useEffect(() => {
    const videosFilter = homeMidia.videos.filter((item) => item.uuid !== videoActive.uuid)
    const hasVideo = videosFilter.map((item) => {
      const isVideo = homeMidia.categories.find((video) => video.name === item.category)

      return isVideo
    })

    const data = hasVideo
      .filter((item) => item !== undefined)
      .map((item) => ({
        ...item,
        active: false,
      }))

    data.unshift({ id: 'todos', name: 'Todos', active: true })

    const newData = data.map((item) => {
      item.active = false

      if (item.name === 'Todos') {
        item.active = true
      }

      return item
    })
    setCategories(newData)
  }, [videoActive])

  const handleClick = (category: string) => {
    const cat = categories.map((item) => {
      item.active = false
      if (item.name === category) {
        item.active = true
      }

      return item
    })

    if (category === 'Todos') {
      const videos = homeMidia.videos.filter((item) => item.uuid !== videoActive.uuid)
      return setvideoCard(ordenate(videos))
    }

    setCategories(cat)
    const videos = homeMidia.videos.filter(
      (item) => item.category === category && item.uuid !== videoActive.uuid,
    )
    return setvideoCard(ordenate(videos))
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-9">
          <div className="d-flex align-items-center mb-4">
            <OrangeIcon icon="video-camera" color="#ff7a00" size="LG" />
            <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 ml-2 mb-0">Vídeos </h2>
          </div>
          {videoActive?.isLive && <Live>Live</Live>}
          <h3 className="fs-20 lh-25 mb-4">{videoActive.title}</h3>
          <p className="fs-18 lh-22 mb-4">{videoActive.description}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-9 mb-4">
          <VideoContainer>
            <YoutubeVideo
              src={videoActive?.link}
              width={isMobile ? '311' : '840'}
              height={isMobile ? '171' : '462'}
              title={videoActive.title}
            />
          </VideoContainer>
        </div>
        <div className="col-12 col-lg-3 mb-4 mb-lg-0">
          <div className="mb-4">
            <ScrollContainer innerRef={scrollContainerRef} component="nav">
              <ListCategory className="list-unstyled">
                {categories.map((cat: CategoryProps) => (
                  <TabCategory key={cat.id} active={cat.active}>
                    <button
                      type="button"
                      onClick={() => {
                        sendDatalayerEvent({
                          section: '',
                          element_action: 'click video',
                          element_name: '',
                          section_name: cat.id,
                        })
                      }}
                    >
                      {cat.name}
                    </button>
                  </TabCategory>
                ))}
              </ListCategory>
            </ScrollContainer>
          </div>
          {isTablet ? (
            <ScrollContainer innerRef={scrollContainerRef} component="nav">
              <ListCategory>
                {videoCard.map((item: VideoProps) => (
                  <VideoCardSmall
                    loading="lazy"
                    src={item.thumbnail.url}
                    alt={item.thumbnail.alt}
                    onClick={() => {
                      setvideoActive(item)
                      sendDatalayerEvent({
                        section: item.category,
                        element_action: 'click video',
                        element_name: item.title,
                        section_name: item.category,
                        redirect_url: item.link,
                      })
                    }}
                  />
                ))}
              </ListCategory>
            </ScrollContainer>
          ) : (
            <VideosContainer>
              {videoCard.map((item: VideoProps) => (
                <VideoCardSmall
                  loading="lazy"
                  src={item.thumbnail.url}
                  alt={item.thumbnail.alt}
                  onClick={() => {
                    setvideoActive(item)
                    sendDatalayerEvent({
                      section: item.category,
                      element_action: 'click video',
                      element_name: item.title,
                      section_name: item.category,
                      redirect_url: item.link,
                    })
                  }}
                />
              ))}
            </VideosContainer>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeMidia
