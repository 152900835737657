import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

type EventContainerProps = {
  isLive: boolean
}

type DayComponentProps = {
  live?: boolean
  news?: boolean
}

export const EventsContainer = styled.div`
  border: 1px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  height: 275px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 24px;
  }

  .capialtize {
    text-transform: capitalize;
  }

  .event {
    &-category {
      font-family: Inter;
      font-size: 10px;
      line-height: 12px;
      color: #6a6c72;

      @media (min-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &-title {
      font-family: Inter;
      font-size: 14px;
      line-height: 17px;
      color: #161616;

      @media (min-width: ${breakpoints.md}) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .mensage {
    height: calc(100% - 44px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 15px;
    margin: 0;
  }
`

export const EventContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  .hours {
    font-family: Inter;
    font-style: normal;
    margin-right: 12px;
    width: 43px;

    &-inital {
      display: block;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 0;

      @media (min-width: ${breakpoints.md}) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-final {
      font-size: 10px;
      line-height: 12px;
      margin: 0;

      @media (min-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .event {
    position: relative;
    display: flex;
    padding: 12px 0;

    &::before {
      display: block;
      content: '';
      width: 4px;
      background: ${(props: EventContainerProps) => (props.isLive ? '#FF7A00' : '#0085FF')};
      border-radius: 2px;
      margin-right: 24px;
    }

    &-category {
      display: block;
    }

    &-title {
      display: block;
      margin: 0;
    }
  }
`

export const Scroll = styled(PerfectScrollbar)`
  max-height: 200px;
  padding: 16px 16px;
`

export const CalendarContainer = styled.div`
  .react-datepicker {
    border: 1px solid #dedfe4;
    border-radius: 16px;
    padding: 10px 15px;
    height: 275px;
    position: relative;
  }

  .react-datepicker__header--custom {
    background: #fff;
    border: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day:not(.react-datepicker__day--selected) {
    position: relative;

    span {
      &::after,
      &::before {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        text-align: center;
      }
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
    background: #161616;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background: #161616;
    color: #fff;
  }
`

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 10px;

  .month {
    font-family: Inter;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
  }

  button {
    background: #fff;
  }
`

export const DayComponent = styled.span`
  width: 26.33px;
  height: 26.33px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;

  &::after,
  &::before {
    content: '';
  }

  &::after {
    content: ${(props: DayComponentProps) => (props.live ? '' : 'none')};
    background: #ff7a00;
    left: ${(props: DayComponentProps) => (props.news ? '9px' : '50%')};
    transform: ${(props: DayComponentProps) => (props.news ? 'none' : 'translateX(-50%)')};
  }

  &::before {
    content: ${(props: DayComponentProps) => (props.news ? '' : 'none')};
    background: #0085ff;
    right: ${(props: DayComponentProps) => (props.live ? '9px' : 'none')};
    left: ${(props: DayComponentProps) => (props.live ? 'none' : '50%')};
    transform: ${(props: DayComponentProps) => (props.live ? 'none' : 'translateX(-50%)')};
  }
`
