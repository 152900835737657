import React, { useEffect, useState, useLayoutEffect } from 'react'

// Services
import { returnAllArticlesOrderPublishDate, SpotlightNewsSmallField } from 'src/services/articles/articles'

// Components
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'

import axios from 'axios'
import SpotlightNewsSmall from 'src/components/SpotlightNewsSmall'
import BestContents from 'src/components/BestContents'

import CalendarEvents from 'src/components/CalendarEvents'

import CategoryHeader from 'src/components/CategoryHeader'
import CarouselTimerMobile from 'src/components/CarouselTimer/CarouselTimerMobile'

// Data
import bestContentJSON from 'src/assets/data/best-content.json'

import useWidth from 'src/hooks/useWidth'
import HeroNews from 'src/components/HeroNews'
// json
import homeMidia from 'src/assets/data/home-midia.json'
import lastContentJSON from 'src/assets/data/last-content.json'
import spotLightJSON from 'src/assets/data/spotlight-articles.json'

import HomeMidia from 'src/components/HomeMidia'
import Newsletter from 'src/components/Newsletter'

// json
import pageContext from './pageContext.json'

const getBannerArticles = spotLightJSON.filter((item) => item.spotlight)

const bannerFill = {
  showCategoryHeader: false,
  template: 'C',
  dados: getBannerArticles,
}

const bannerFill2 = {
  showCategoryHeader: false,
  template: 'D',
  dados: bestContentJSON,
}

const Home = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(true)
  const [moreRead, setMoreRead] = useState([])

  const windowWidth = useWidth(200)
  useLayoutEffect(() => {
    setIsMobile(windowWidth < 768)
    setIsTablet(windowWidth < 1024)
  }, [windowWidth])

  useEffect(() => {
    returnArticlesMoreRead()
  }, [])

  async function returnArticlesMoreRead() {
    try {
      const articles = returnAllArticlesOrderPublishDate()
      const response = await axios.post(`${process.env.CMS_API}/news/analyticsdata`)
      const { data } = response
      const resposta = data.reduce((all, item: AnalyticsData) => {
        const artigo = articles.find((articleFind) => articleFind.id === item.uuid)
        if (!artigo) {
          return all
        }
        return [...all, SpotlightNewsSmallField(artigo)]
      }, [])
      setMoreRead(resposta)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Layout pageContext={pageContext} isHome>
      <SEO
        title={pageContext.metaTitle}
        url={process.env.BASE_URL}
        meta={[
          {
            name: 'description',
            content: pageContext.metaDescription,
          },
          {
            property: 'og:title',
            content: pageContext.metaTitle,
          },
          {
            property: 'og:description',
            content: pageContext.metaDescription,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}`,
          },
          {
            property: 'og:site_name',
            content: 'Banco Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <section className="py-4">
        <div className="container">
          {!isMobile ? (
            <HeroNews data={getBannerArticles} />
          ) : (
            <CarouselTimerMobile data={bannerFill} />
          )}
        </div>
      </section>
      <Newsletter page="home" />

      <CalendarEvents />
      {homeMidia.videos && homeMidia.videos.length > 0 && (
        <section>
          <HomeMidia />
        </section>
      )}

      <section className="pt-5 pb-4">
        <div className="container">
          <BestContents bannerFill={bannerFill2} />
        </div>
      </section>
      <section className="pt-5 pb-4">
        <div className="container">
          <CategoryHeader category="Últimos conteúdos" className="mb-3" />
          <SpotlightNewsSmall data={lastContentJSON} />
        </div>
      </section>
    </Layout>
  )
}

export default Home
