import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import themes from 'src/styles/themes'

import { Card, CardBigNews, Label } from './style'

type categoryPros = {
  icon: string
  id: string
  name: string
  slug: string
  subTitle: string
  template: string
}

type dataProps = {
  showCategoryLabel: string
  category: categoryPros
  image: string
  alt: string
  slug: string
  title: string
  subTitle: string
  categorySlug: string
  subcategorySlug: string
  segmentSlug: string
  subCategory: string
  isFromInset?: boolean
  insetCategory?: string
}

type HeroNewsProps = {
  data: dataProps[]
}

const HeroNews = ({ data }: HeroNewsProps) => {
  const [sendDatalayerEvent] = useDataLayer()

  const filterData = (filteredData: dataProps[]) => filteredData.filter((item) => item.slug.includes('bom-dia-inter'))
  const filteredData = filterData(data)

  const filterDataSlug = (filteredDataSlug: dataProps[]) => filteredDataSlug.filter((item) => !item.slug.includes('bom-dia-inter'))
  const filteredDataSlug = filterDataSlug(data)

  const searchColor = (category: string) => {
    return themes.find((item) => item.title === category)
  }

  return (
    <div className="row">
      {filteredData[0] && (
        <div className="col-12 col-md-8 col-lg-6">
          <CardBigNews>
            <a
              href={
                !filteredData[0].isFromInset
                  ? `/${filteredData[0].categorySlug}/${filteredData[0].subcategorySlug}/${filteredData[0].slug}`
                  : `https://www.inset.com.br/${filteredData[0].insetCategory}/${filteredData[0].slug}`
              }
              onClick={() => {
                sendDatalayerEvent({
                  section: filteredData[0].title,
                  element_action: 'click banner',
                  element_name: filteredData[0].title,
                  redirect_url: `${process.env.BASE_URL}/${filteredData[0].categorySlug}/${filteredData[0].subcategorySlug}/${filteredData[0].slug}`,
                })
              }}
              target={filteredData[0].isFromInset ? '__blank' : ''}
              rel={filteredData[0].isFromInset ? 'noreferrer' : ''}
              title={filteredData[0].title}
            >
              <figure>
                {filteredData[0].image && <img loading="lazy" src={filteredData[0].image} alt={filteredData[0].alt} />}
                <figcaption className="d-flex align-items-end">
                  <Label bg={searchColor(filteredData[0].categorySlug)?.colors.primary}>
                    {filteredData[0].subCategory}
                  </Label>
                  <h3 className="fs-20 lh-25 text-white">{filteredData[0].title}</h3>
                </figcaption>
              </figure>
            </a>
          </CardBigNews>
        </div>
      )}
      {filteredDataSlug[0] && (
        <div className="col-12 col-md-4 col-lg-6">
          <div className="row">
            <div className="col-12 col-lg-6 mb-md-3 mb-lg-0">
              <Card>
                <a
                  href={
                    !filteredDataSlug[0].isFromInset
                      ? `/${filteredDataSlug[0].categorySlug}/${filteredDataSlug[0].subcategorySlug}/${filteredDataSlug[0].slug}`
                      : `https://www.inset.com.br/${filteredDataSlug[0].insetCategory}/${filteredDataSlug[0].slug}`
                  }
                  onClick={() => {
                    sendDatalayerEvent({
                      section: filteredDataSlug[0].title,
                      element_action: 'click banner',
                      element_name: filteredDataSlug[0].title,
                      redirect_url: `${process.env.BASE_URL}/${filteredDataSlug[0].categorySlug}/${filteredDataSlug[0].subcategorySlug}/${filteredDataSlug[0].slug}`,
                    })
                  }}
                  target={filteredDataSlug[0].isFromInset ? '__blank' : ''}
                  rel={filteredDataSlug[0].isFromInset ? 'noreferrer' : ''}
                  title={filteredDataSlug[0].title}
                >
                  <figure>
                    {filteredDataSlug[0].image && <img loading="lazy" src={filteredDataSlug[0].image} alt="" />}
                    <figcaption className="d-flex align-items-end">
                      <Label bg={searchColor(filteredDataSlug[0].categorySlug)?.colors.primary}>
                        {filteredDataSlug[0].subCategory}
                      </Label>
                      <h3 className="fs-20 lh-25 text-white">{filteredDataSlug[0].title}</h3>
                    </figcaption>
                  </figure>
                </a>
              </Card>
            </div>
            {filteredDataSlug[1] && (
              <div className="col-12 col-lg-6">
                <Card>
                  <a
                    href={
                      !filteredDataSlug[1].isFromInset
                        ? `/${filteredDataSlug[1].categorySlug}/${filteredDataSlug[1].subcategorySlug}/${filteredDataSlug[1].slug}`
                        : `https://www.inset.com.br/${filteredDataSlug[1].insetCategory}/${filteredDataSlug[1].slug}`
                    }
                    onClick={() => {
                      sendDatalayerEvent({
                        section: filteredDataSlug[1].title,
                        element_action: 'click banner',
                        element_name: filteredDataSlug[1].title,
                        redirect_url: `${process.env.BASE_URL}/${filteredDataSlug[1].categorySlug}/${filteredDataSlug[1].subcategorySlug}/${filteredDataSlug[1].slug}`,
                      })
                    }}
                    target={filteredDataSlug[1].isFromInset ? '__blank' : ''}
                    rel={filteredDataSlug[1].isFromInset ? 'noreferrer' : ''}
                    title={filteredDataSlug[1].title}
                  >
                    <figure>
                      {filteredDataSlug[1].image && <img loading="lazy" src={filteredDataSlug[1].image} alt="" />}
                      <figcaption className="d-flex align-items-end">
                        <Label bg={searchColor(filteredDataSlug[1].categorySlug)?.colors.primary}>
                          {filteredDataSlug[1].subCategory}
                        </Label>
                        <h3 className="fs-20 lh-25 text-white">{filteredDataSlug[1].title}</h3>
                      </figcaption>
                    </figure>
                  </a>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HeroNews
